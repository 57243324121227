<template>
  <div v-if="showGlobalFloater" class="global-floater">
    <ul class="item-list">
      <li>
        <el-button
          title="Click to refresh"
          type="primary"
          class="item"
          :loading="refreshPointsButtonLoadingState"
          @click="refreshMyPoints"
          link
        >
          My Points:
          <span style="color: red; font-weight: bold">{{ this.pointsData.points }}</span>
        </el-button>
      </li>
      <li>
        <el-button
          title="Click to Top Up"
          type="primary"
          class="item"
          style="background: green; opacity: 0.8"
          @click="topUpPoints"
          link
        >
          <el-icon> <Select /> </el-icon>
          TOP UP
        </el-button>
      </li>
    </ul>
  </div>
  <div class="header">
    <div class="collapse-btn" @click="collapseChange">
      <el-icon v-if="!collapse">
        <fold />
      </el-icon>
      <el-icon v-else>
        <expand />
      </el-icon>
    </div>
    <div class="logo" v-if="!collapse">JE Education Student Management</div>
    <el-carousel
      height="70px"
      direction="vertical"
      :autoplay="true"
      arrow="never"
      indicator-position="none"
    >
      <el-carousel-item>
        <div class="promotionSection">
          <a
            target="_blank"
            id="promotionButton1"
            class="promotion"
            :href="this.promotionLink"
          >
            PROMOTION !!!
          </a>
          <Close
            v-if="showStopBlinkerButton1"
            title="Stop blinkering"
            class="closeButton"
            @click="stopBlinkering('promotionButton1', 'showStopBlinkerButton1')"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="header-right">
      <div class="header-user-con">
        <!--notification-->
        <el-popover :width="this.$widthRatio * 400 + 'px'" trigger="click">
          <template #reference>
            <el-badge
              :hidden="getUnreadNotificationCount() <= 0"
              :value="getUnreadNotificationCount()"
              :max="99"
            >
              <el-icon>
                <Bell style="width: 32px; height: 32px; margin: 2px 0 0 -18px" />
              </el-icon>
            </el-badge>
          </template>
          <el-collapse v-model="activeNames" @change="handleNotificationClick">
            <el-collapse-item
              v-for="(item, index) in notificationList"
              :title="item.subject"
              :name="item"
              :key="index"
            >
              <template #title>
                <span :class="item.status == 4 ? 'unread_dot' : 'read_dot'"></span>
                {{ item.subject.substring(0, 42) }}
                <span class="time_block">{{
                  this.$formatter.smartFormatDate(item.updatedTime)
                }}</span>
              </template>
              <div v-html="item.body"></div>
              <component v-bind:is="item.eventComponent"></component>
            </el-collapse-item>
          </el-collapse>
        </el-popover>
        <!-- user picture -->
        <div class="user-avatar">
          <a href="javascript:void(0)" @click="gotoProfile"><img :src="profileUrl" /></a>
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleUserActions">
          <span class="el-dropdown-link">
            {{ username }}
            <el-icon>
              <caret-bottom />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="logOut">Sign Out</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
  <el-dialog
    title="Session expired warning!"
    v-model="showSessionExpiredAlert"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="420px"
  >
    <el-row>
      Your session is already expired! before you quit out this page, you can click Login
      button to get the latest session and then continue on your work.
    </el-row>
    <el-row class="buttonLayer">
      <el-button type="warning" @click="gotoLogin">Login</el-button>
    </el-row>
  </el-dialog>
  <el-dialog title="Top Up Points" v-model="showTopUpDialog" width="40%">
    <top-up-points />
  </el-dialog>
  <common :isForAdmin="false"></common>
</template>
<script>
import { postData } from "../../service/api/index";
import TopUpPoints from "../../views/common/TopUpPoints.vue";
import Common from "../common/Common.vue";

export default {
  components: {
    TopUpPoints,
    Common,
  },
  data() {
    return {
      name: "header",
      promotionLink: "",
      showStopBlinkerButton1: true,
      showStopBlinkerButton2: true,
      refreshPointsButtonLoadingState: false,
      notificationList: [],
      activeNames: [],
    };
  },
  created() {
    this.$user.buildProfileUrl();
    this.promotionLink = this.$appSetting.getGenericValue(
      "PROMOTION_LINK_FOR_STUDENT",
      "https://drive.google.com/file/d/1LtN8C56mqpguIo5PwKlrvr2rvSNuQYcC/view?usp=sharing"
    );

    // after dom updated, set isLoading to false
    this.$nextTick(async function () {
      this.notificationList = await this.$user.getNotifications();

      let blinker = this.$user.getProfile("blinker_promotion_button_promotionButton1", 1);
      if (blinker === -1) {
        this.stopBlinkering("promotionButton1", "showStopBlinkerButton1");
      }
      blinker = this.$user.getProfile("blinker_promotion_button_promotionButton2", 1);
      if (blinker === -1) {
        this.stopBlinkering("promotionButton2", "showStopBlinkerButton2");
      }
    });
  },
  mounted() {},
  computed: {
    username() {
      let username = this.$user.getUsername();
      return username ? username : this.name;
    },
    profileUrl() {
      return this.$store.state.userProfileUrl;
    },
    collapse() {
      return this.$store.state.collapse;
    },
    pointsData() {
      return this.$store.state.pointsData;
    },
    showGlobalFloater() {
      const pathList = ["/dashboard"];
      return (
        pathList.indexOf(this.$route.fullPath) >= 0 && this.$store.state.showGlobalFloater
      );
    },
    showTopUpDialog() {
      return this.$store.state.showTopUpDialog;
    },
  },
  methods: {
    collapseChange() {
      this.$store.commit("handleCollapse", !this.collapse);
    },
    gotoLogin() {
      let route = this.$router.resolve({ path: "/login" });
      this.$appSetting.loginWindow = window.open(route.href, "_blank");
    },
    stopBlinkering(btnId, showStopBlinkerButton) {
      let promotionButton = document.getElementById(btnId);
      promotionButton.setAttribute("class", "promotion_no_blinker");
      this.$user.setProfile(`blinker_promotion_button_${btnId}`, -1);
      this[showStopBlinkerButton] = false;
    },
    async refreshMyPoints() {
      this.refreshPointsButtonLoadingState = true;
      await this.$user.refreshPoints();
      this.refreshPointsButtonLoadingState = false;
    },
    async topUpPoints() {
      this.$store.commit("handleShowTopUpDialog", !this.showTopUpDialog);
    },
    handleNotificationClick(notificationList) {
      console.log("handleClick", notificationList, this.activeNames);
      for (let i = 0; i < notificationList.length; i++) {
        const notification = notificationList[i];
        if (notification.status === 4) {
          notification.status = 64;
          postData(
            "notification/mark",
            {
              recordList: [
                {
                  notificationId: notification.notificationId,
                  receiverId: this.$user.getUsername(),
                  receiverName: this.$user.getUsername(),
                  status: notification.status,
                },
              ],
            },
            {
              isShowLoading: false,
            }
          ).then((res) => {
            if (res.result && res.code === "200") {
              console.log("Mark notification successfully");
            } else {
              this.$message.error(
                "Mark notification failed, error message: " + res.message
              );
            }
          });
        }
      }
    },
    getUnreadNotificationCount() {
      return this.notificationList.filter((e) => e.status == 4).length;
    },
    gotoProfile() {
      this.$router.push("/myProfile");
    },
    handleUserActions(command) {
      switch (command) {
        case "logOut":
          this.$emitter.emit("SIGN_OUT");
          break;
      }
    },
  },
};
</script>
<style scoped>
.global-floater {
  position: absolute;
  right: 44px;
  top: 200px;
  z-index: 10000;
}

.global-floater ul.item-list li {
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;
}

.global-floater ul.item-list li {
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;
}

.global-floater ul.item-list li .item {
  width: 100%;
}

.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 1.84rem;
  color: #ffa500;
}

.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}

.header .logo {
  float: left;
  width: 500px;
  line-height: 70px;
  font-size: 2.34rem;
}

.header .promotionSection {
  display: inline;
  margin-left: 100px;
}

.header .closeButton {
  width: 1em;
  height: 1em;
  color: #ffffff;
  cursor: pointer;
}

.header .promotionSection .promotion,
.header .promotionSection .promotion_no_blinker {
  font-size: 2rem;
  font-weight: bold;
  line-height: 70px;
  color: red;
  text-shadow: 1px 1px 2px red(255, 238, 0), 0 0 1em red, 0 0 0.2em rgb(139, 51, 0);
}

.header .promotionSection .promotion {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.header-right {
  top: 0;
  right: 50px;
  position: absolute;
}

.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 2rem;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.user-name {
  margin-left: 10px;
}

.user-avatar {
  margin-left: 20px;
}

.user-avatar img {
  display: block;
  height: 40px;
  /* border-radius: 50%; */
}

.el-dropdown-link {
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}

.el-dropdown-menu__item {
  text-align: center;
}

.buttonLayer {
  margin-top: 20px;
}

.buttonLayer button {
  margin-left: auto;
}

.el-dropdown-menu__item {
  display: block;
}

.read_dot,
.unread_dot {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.read_dot {
  background-color: transparent;
}

.time_block {
  font-size: 0.84rem;
  color: gray;
  margin-left: 4px;
  line-height: 16px;
}

.el-button.is-circle {
  padding: 10px;
}
</style>
